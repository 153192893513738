import React, { useEffect } from 'react';
import { Button, Figure } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import DMILogo from '../assets/DiagnoseMI-Logo-HQ.png'
import './HomePage.css'

export default function HomePage() {
    
        return (
            <div className='home'>
                <div className="DMILogo" >
                    <Image src={DMILogo} fluid as="h1" title="Diagnose MI Logo" alt="Diagnose MI Logo" realtive />
                </div>
                <div>
                    <Link to="/instructions" replace>
                        <Button className="homeButton" variant="danger">Click Here to Start</Button>
                    </Link>
                </div>
            </div>
        );
    
}