import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {Button, Image} from 'react-bootstrap';
import '../App.css';
import ECGPlacement from '../assets/ecgPlacement.png'
import './ECGPage.css';

export default function ECGPage() {
    // const history = useHistory();
    
    // console.log(history.location.pathname); // '/about'

    return (
        // <>
        // <h1>ON ECG PAGE: {history.location.pathname}</h1>
        // <button onClick={() => history.replace('/connect')}>Go to Connect</button>
        // </>
    <div id="ecg-page-layout">
         <h1>Place Electrodes as Shown</h1>
         <div>
             <Image className="ECGPic" src={ECGPlacement} fluid as="h1" title="ECG Application Areas" alt="ECG Application Areas" realtive />
         </div>
         <div>
             <ul>
                 <li>Top 2 electrodes are symmetrical and beneath the collarbone</li>
                 <li>Colors of electrodes cables should match placement in the diagram</li>
                 <li>Bottom red electrode is just above the hip bone</li>
             </ul>
         </div>
         <div>
             <Link to="/connect" replace>
                 <Button variant="danger" size="lg">Next</Button>
             </Link>
         </div>
     </div>
    );
//   return (
//       <div>
//         <p>ECG Page</p>
//         <Link to="/connect">Connect</Link>
//       </div>
//   );
}