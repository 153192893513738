import React, {useEffect, useState} from 'react';
import '../App.css';
import { useHistory, Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import './ConnectPage.css';
import bluetoothBlue from '../assets/bluetooth-blue.svg'
import bluetoothGrey from '../assets/bluetooth-grey.svg'


function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const messageOptions = {
  searching: 'Searching for Device...',
  found: 'Device Not Found',
}

const linkOptions = {
  disabled: 'disabled-link',
  enabled: 'enabled-link',
}


export default function ConnectPageD() {
  
  const [imageB, setImageB] = useState(bluetoothGrey)
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState(messageOptions.searching)
  const [buttonLink, setButtonLink] = useState(linkOptions.disabled)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  function getImage(count){
    if (imageB !== bluetoothBlue && count >= 100){
      setImageB(bluetoothBlue);
    } else {
      return imageB
    }
  }

  function getMessage(count){
    if (message !== messageOptions.found && count >= 100){
      setMessage(messageOptions.found);
    } else {
      return message
    }
  }

  function getButtonLink(count){
    if (buttonLink !== linkOptions.enabled && count >= 100){
      setButtonLink(linkOptions.enabled);
    } else {
      return buttonLink
    }
  }

  function getButtonActivityStatus(){
    if (isButtonDisabled && count >= 100){
      setIsButtonDisabled(false);
    } else {
      return isButtonDisabled
    }
  }

  useEffect(() => {
    const id = setInterval(() => setCount((oldCount) => oldCount + getRandomInt(5)), 100);

    return () => {
        clearInterval(id);
    };
    }, []);

  
    return (
  
    <div id='connect-layout'>
      <h1>Activate and Connect Device</h1>
      <h2>Step 1</h2>
      <p>Turn on device by flipping the switch. Wait for green light to appear.</p>
      <h2>Step 2</h2>
      <p>Wait for confirmation message below to show that Bluetooth is connected, then start the ECG recording.</p>

      <Image src={bluetoothGrey} className='bluetooth-img' fluid alt-text="Bluetooth-img add" ></Image>
      <div>
        <h6>{getMessage(count)}</h6>
      </div>
      <div>
          <Link to="/loading" className={linkOptions.disabled} replace>
              <Button variant="danger" size="lg" disabled={true}>Start ECG Recording</Button>
          </Link>
      </div>
    </div>
  );
  }