import React from 'react';
import './App.css';
import HomePage from './Components/HomePage.js'
import ConnectPage from './Components/ConnectPage';
import ConnectPageD from './Components/ConnectPageD';
import InstructionsPage from './Components/InstructionsPage';
import ResultsPage from './Components/ResultsPage';
import ResultsPageYellow from './Components/ResultsPageYellow';
import ResultsPageGreen from './Components/ResultsPageGreen';
import LoadingPage from './Components/LoadingPage';
import ECGPage from './Components/ECGPage';
import ProcessingPage from './Components/ProcessingPage';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Link } from "react-router-dom";

// in your build static folder you need a file called redirects with /*    /index.html  200

export default function App() {
  return (
    <div>
      {/* <h1>Base Page</h1> */}
        <div>
          {/* <p>App.js</p>
          <Link to="/home">Click Here</Link> */}
          <Router>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/instructions" component={InstructionsPage} />
              <Route exact path="/ecg-page" component={ECGPage} />
              <Route exact path="/connect" component={ConnectPageD} />
              {/* <Route exact path="/results" component={ResultsPageGreen} />
              <Route exact path="/loading" component={LoadingPage} />
              <Route exact path="/process" component={ProcessingPage} /> */}
            </Switch> 
          </Router>
           
          
            {/* <Route exact path="/not-found" component={NotFound} /> */}
            {/* <Redirect to="/not-found" /> */}
        </div>
    </div>
    // <div className='App'>
    //   <div>
    //     <Button variant="danger" className='danger'>Next</Button>{' '}
    //     <Button variant="danger" bsStyle="primary">Next</Button>{' '}
    //     <Button variant="primary">Primary</Button> 
    //   </div>
    // </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

// export default App;
