import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import '../App.css';
import ECGPlacement from '../assets/ecgApplicationAreas.png'
import './InstructionsPage.css';

export default function InstructionsPage() {
    // const history = useHistory();
    
    // console.log(history.location.pathname); // '/about'

    // return (
    //     <>
    //     <h1>The about page is on: {history.location.pathname}</h1>
    //     <button onClick={() => history.replace('/ecg-page')}>Go to home page</button>
    //     </>
    // );

    return (
        <div id="instruct-layout">
            <h1>Clean Application Areas</h1>
            <div>
                <Image className="ECGPic" src={ECGPlacement} fluid as="h1" title="ECG Application Areas" alt="ECG Application Areas" realtive />
            </div>
            <div>
                <h2 className='instructions'>Instructions:</h2>
                <ol type="1">
                    <li>Clean marked areas with soap and water</li>
                    <li>Dry cleaned areas</li>
                    <li> Remove any excessive hair at marked areas by clipping</li>
                </ol>
            </div>
            <div>
                <Link to="/ecg-page" replace>
                    <Button variant="danger" size="lg">Next</Button>
                </Link>
            </div>
        </div>
    );

    // const history = useNavigate();

    // function handleSubmit(e) {
    //     e.preventDefault();

    //     history('/ecg-page');
    // }

    // return (
    //     <form onSubmit={handleSubmit}>
    //         <button>Subimt</button>
    //     </form>
    // )

    // let navigate = useNavigate();
    // function handleClick() {
    //     navigate("/ecg-page");
    // }
    // return (
    //     <div>
    //     <p>Instructions Page</p>
    //     <button onClick={handleClick}>go ecg</button>
    //     </div>
    // );
    // const history = useNavigate();
    
    // console.log(history.location.pathname); // '/about'

    // return (
    //     <>
    //     <h1>The about page is on: {history.location.pathname}</h1>
    //     <button onClick={() => history.replace('/ecg-page')}>Go to home page</button>
    //     </>
    // );

//   return (
//       <div>
//           <p>Instructions Page</p>
//           <Link to="/ecg-page">ECG</Link>
//       </div>
      
//   );
}